import { pick } from 'lodash';
import { Source } from '@/generated/graphql';
import imageUrlBuilder from '@sanity/image-url';
import { sanityClient } from '@/content/sanity/sanity-client/sanity-client';

const builder = imageUrlBuilder(sanityClient);

interface ImageSource {
  src: string;
  width: number;
  height: number;
}

export const buildImageSource = (source?: Partial<Source>): ImageSource | null => {
  if (!source) {
    return null;
  }
  if (!source?.src) return null;
  if (!source?.width) return null;
  if (!source?.height) return null;

  return pick(source as ImageSource, ['src', 'width', 'height']);
};

export const urlFor = (source: string) => {
  try {
    return builder.image(source).url();
  } catch (e) {
    return source;
  }
};
