/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import MuiLink from '@mui/material/Link';
import clsx from 'clsx';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { usePathname } from 'next/navigation';
import React, { ForwardedRef, forwardRef } from 'react';

type NextComposedInnerProps = Pick<NextLinkProps, 'as' | 'href' | 'passHref'> & {
  className?: string;
  children: string | React.ReactNode | React.ReactNode[];
  target?: string;
  onClick?: (event: unknown) => void;
};

const NextComposedInner = (props: NextComposedInnerProps, ref: ForwardedRef<HTMLAnchorElement>) => {
  const { as, href, children, target, onClick, ...other } = props;
  return (
    <NextLink href={href} as={as} legacyBehavior style={{ textDecoration: 'none' }}>
      <a
        role="button"
        style={{ textDecoration: 'none' }}
        ref={ref}
        target={target}
        onClick={onClick}
        {...other}
      >
        {children}
      </a>
    </NextLink>
  );
};

const NextComposed = forwardRef<HTMLAnchorElement, NextComposedInnerProps>(NextComposedInner);

export type LinkProps = NextComposedInnerProps & {
  activeClassName?: string;
  className?: string;
  innerRef?: ForwardedRef<HTMLAnchorElement>;
  naked?: boolean;
};

const LinkComposed = (props: LinkProps, ref: ForwardedRef<HTMLAnchorElement>) => {
  const { href, activeClassName = 'active', className: classNameProps, naked, ...other } = props;

  const router = {
    pathname: usePathname() || '',
  };
  const pathname = typeof href === 'string' ? href : href?.pathname;
  const className = clsx(classNameProps, {
    [activeClassName]: pathname && router.pathname.indexOf(pathname) === 0 && activeClassName,
  });

  if (naked) {
    return <NextComposed className={className} ref={ref} href={href} {...other} />;
  }

  return (
    <MuiLink
      style={{ textDecoration: 'none' }}
      component={NextComposed}
      href={`${href}`}
      className={className}
      ref={ref}
      {...other}
    />
  );
};

const Link = forwardRef<HTMLAnchorElement, LinkProps>(LinkComposed);

export { Link };
