import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
import '@algolia/autocomplete-theme-classic';
import styled from '@emotion/styled';
import algoliasearch from 'algoliasearch/lite';
import get from 'lodash/get';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import {
  Fragment,
  ReactElement,
  ReactNode,
  createElement,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { createRoot } from 'react-dom/client';
import { config } from '../../config/environment';
import { MediaContainer } from '../media-container/media-container';

const EXPERIENCES_SEARCH_INDEX_SUFFIX = 'experiences';
const EXPERIENCES_SEARCH_INDEX = `${config.algoliaIndexPrefix}${EXPERIENCES_SEARCH_INDEX_SUFFIX}`;

const useSearchClient = () => {
  if (!config.algoliaApplicationId || !config.algoliaSearchKey) {
    return null;
  }
  return algoliasearch(config.algoliaApplicationId, config.algoliaSearchKey);
};

const ExperienceSearchItemContainer = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  text-align: left;
  padding: 1rem !important;
  display: flex;
  flex-direction: row;

  &:focus {
    outline: none;
  }
`;

const ExperienceSearchImageContainer = styled.div`
  margin-right: 20px;
`;

const Text = styled.p`
  font-family: var(--font-work-sans), sans-serif;
  line-height: 1.5;
  margin: 0;
`;

const ExperienceSearchItemTitle = styled(Text)`
  margin-bottom: 10px !important;
  font-size: 18px;
  font-weight: 600;
`;

const ExperienceSearchItemDescription = styled(Text)`
  font-size: 16px;
  line-height: 1.5;
`;

const StyledMediaContainer = styled(MediaContainer)`
  width: 150px;
  height: 120px;
  border-radius: 20px;
  overflow: hidden;
`;

const SearchContainer = styled.div`
  width: 100%;

  --aa-font-family: var(--font-work-sans), sans-serif;
  --aa-text-color-rgb: 35, 23, 39;
  --aa-icon-color-rgb: 35, 23, 39;
  --aa-input-border-color-rgb: 76, 59, 81;
  --aa-input-border-color-alpha: 0;
  --aa-input-background-color-rgb: ${props =>
    props.className === 'inPerson'
      ? '255, 255, 255'
      : props.className === 'online'
        ? '174, 138, 168'
        : '76, 59, 81'};

  .aa-DetachedSearchButton {
    border-radius: 50px;
  }

  .aa-DetachedSearchButtonIcon {
    color: #fdf3de;
  }

  .aa-DetachedSearchButtonPlaceholder {
    font-family: var(--font-work-sans), sans-serif;
    font-weight: 600;
    font-size: 15px;
    line-height: 136.8%;
    color: #fdf3de;
  }
`;

type ExperienceSearchItemObject = {
  objectID: string;
  title: string;
  description: string;
  type?: string;
  category?: string;
  picture?: string;
  pictureFile?: string;
};

type ExperienceSearchItemProps = {
  experience?: ExperienceSearchItemObject;
  onClick?: () => void;
};

const ExperienceSearchItem = ({ experience, onClick }: ExperienceSearchItemProps) => {
  if (!experience?.title) {
    return null;
  }
  return (
    <ExperienceSearchItemContainer onClick={onClick}>
      <ExperienceSearchImageContainer>
        {experience?.pictureFile && (
          <StyledMediaContainer>
            <Image alt="Experience Image" src={experience?.pictureFile} />
          </StyledMediaContainer>
        )}
      </ExperienceSearchImageContainer>
      <div>
        <ExperienceSearchItemTitle>{experience?.title}</ExperienceSearchItemTitle>
        {experience?.description && (
          <ExperienceSearchItemDescription>
            {experience.description}
          </ExperienceSearchItemDescription>
        )}
      </div>
    </ExperienceSearchItemContainer>
  );
};

export const ExperienceSearch = () => {
  const router = useRouter();
  const containerRef = useRef<any>(null);
  const searchClient = useSearchClient();

  const handleClick = useCallback(
    (experience: { objectID: any }) => {
      const detachedElement = document.querySelector('.aa-Detached');
      if (detachedElement) {
        detachedElement.removeAttribute('class');
      }
      router.push(`/experiences/${experience.objectID}/`);
    },
    [router],
  );

  const handleSubmit = useCallback(
    (event: { state: { activeItemId: any } }) => {
      const detachedElement = document.querySelector('.aa-Detached');
      if (detachedElement) {
        detachedElement.removeAttribute('class');
      }
      const activeItemId = event?.state?.activeItemId;
      if (activeItemId) {
        router.push(`/experiences/${activeItemId}/`);
        return;
      }

      const firstItemId = get(event, 'state.collections[0].items[0].objectID');
      if (firstItemId) {
        router.push(`/experiences/${firstItemId}/`);
      }
    },
    [router],
  );
  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const search = autocomplete<ExperienceSearchItemObject>({
      container: containerRef.current,
      renderer: { createElement, Fragment },
      render({ children }, root) {
        const newRoot = createRoot(root);
        newRoot.render(children as any);
      },
      openOnFocus: true,
      detachedMediaQuery: '',
      autoFocus: true,
      onSubmit: handleSubmit,
      placeholder: 'Search Experiences',
      getSources: ({ query }) => [
        {
          sourceId: 'experiences',
          getItems() {
            return getAlgoliaResults({
              searchClient: searchClient!,
              queries: [
                {
                  indexName: EXPERIENCES_SEARCH_INDEX,
                  query,
                },
              ],
            });
          },
          attributesToRetrieve: ['picture', 'title', 'description', 'type', 'category', 'objectID'],
          templates: {
            item({ item }) {
              return (
                <ExperienceSearchItem
                  key={item.objectID}
                  experience={item}
                  onClick={() => handleClick(item)}
                />
              );
            },
          },
        },
      ],
    });
    if (containerRef.current) {
      const detachedElement = containerRef.current.querySelector(
        '.aa-DetachedSearchButtonPlaceholder',
      );
      if (detachedElement) {
        detachedElement.innerText = 'Search';
      }
    }

    return () => {
      search.destroy();
    };
  });

  return <SearchContainer ref={containerRef} />;
};
